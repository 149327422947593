import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Page404 from "./pages/Page404";
import PostPage from "./pages/PostPage";
import PostContextProvider from "./contexts/PostContextProvider";
import PostDetailPage from "./pages/PostPage/PostDetailPage";
import PostOwnerPage from "./pages/PostPage/PostOwnerPage";
import PageTemplate from "./components/PageTemplate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageTemplate />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "posts/:postId",
        element: (
          <PostContextProvider>
            <PostPage />
          </PostContextProvider>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="detail" replace />,
          },
          {
            path: "detail",
            element: <PostDetailPage />,
          },
          {
            path: "owner/:userId",
            element: <PostOwnerPage />,
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
