import { Link } from "react-router-dom";
import classes from "./classes.module.css";

function MainHeader() {
  return (
    <header className={classes.root}>
      <h1>React Typescript Exam</h1>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </header>
  );
}

export default MainHeader;
