function MainFooter() {
  return (
    <footer>
      <p>
        Par <i>Rael CALITRO</i>, le{" "}
        <time dateTime="2023-11-06">06/11/2023</time>
      </p>
    </footer>
  );
}

export default MainFooter;
