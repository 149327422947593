import { PropsWithChildren, createContext, useEffect, useState } from "react";
import Post from "../models/Post";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPost } from "../services/api/jsonplaceholder/posts";
import { ValidationUtils } from "../utils/ValidationUtils";

type PostContextType = {
  post: Post | null;
};

export const PostContext = createContext<PostContextType>(undefined!);

function PostContextProvider({ children }: PropsWithChildren) {
  const params = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<Post | null>(null);

  const postId = Number(params.postId);

  useEffect(() => {
    if (!ValidationUtils.isIdValid(postId)) {
      navigate("/not-found", { replace: true });
    }

    fetchPost(postId)
      .then((post) => {
        setPost(post);
      })
      .catch(() => {
        navigate("/not-found", { replace: true });
      });
  }, [navigate, postId]);

  return (
    <PostContext.Provider value={{ post }}>{children}</PostContext.Provider>
  );
}

export default PostContextProvider;
