import { useContext } from "react";
import { PostContext } from "../../../contexts/PostContextProvider";
import PostCard from "../../../components/PostCard";

function PostDetailPage() {
  const { post } = useContext(PostContext);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PostCard post={post} />
    </div>
  );
}

export default PostDetailPage;
