import { Outlet } from "react-router-dom";
import MainHeader from "../MainHeader";
import MainFooter from "../MainFooter";

function PageTemplate() {
  return (
    <>
      <MainHeader />
      <main>
        <Outlet />
      </main>
      <MainFooter />
    </>
  );
}

export default PageTemplate;
