import { Link } from "react-router-dom";

function Page404() {
  return (
    <>
      <p>404 Page</p>
      <p>
        <Link to="/">Return to home page</Link>
      </p>
    </>
  );
}

export default Page404;
