import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { PostContext } from "../../contexts/PostContextProvider";
import classes from "./classes.module.css";

function PostPage() {
  const { post } = useContext(PostContext);

  return (
    <div className={classes.root}>
      {post && (
        <>
          <nav className={classes.nav}>
            <ul>
              <li>
                <Link to="detail">Post details</Link>
              </li>
              <li>
                <Link to={`owner/${post.userId}`}>Owner details</Link>
              </li>
            </ul>
          </nav>
          <Outlet />
        </>
      )}
    </div>
  );
}

export default PostPage;
