import { useEffect, useState } from "react";
import Post from "../../models/Post";
import { Link } from "react-router-dom";
import { fetchPosts } from "../../services/api/jsonplaceholder/posts";

function HomePage() {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    fetchPosts().then((posts) => setPosts(posts));
  }, []);

  return (
    <ul>
      {posts.map((post) => (
        <li>
          <Link key={post.id} to={`posts/${post.id}/detail`}>
            {post.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default HomePage;
