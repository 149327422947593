import { rootUrl } from ".";
import User from "../../../models/User";

const usersUrl = `${rootUrl}/users`;

export async function fetchUser(id: number): Promise<User> {
  return fetch(`${usersUrl}/${id}`).then((response) => {
    if (!response.ok) throw new Error(response.statusText);
    return response.json();
  });
}
