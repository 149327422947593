import Post from "../../models/Post";

type Props = {
  post: Post;
};

function PostCard({ post }: Props) {
  return (
    <div>
      <h2>{post.title}</h2>
      <p>{post.body}</p>
    </div>
  );
}

export default PostCard;
