import { rootUrl } from ".";
import Post from "../../../models/Post";

const postsUrl = `${rootUrl}/posts`;

export async function fetchPosts(): Promise<Post[]> {
  return fetch(postsUrl).then((response) => {
    if (!response.ok) throw new Error(response.statusText);
    return response.json();
  });
}

export async function fetchPost(id: number): Promise<Post> {
  return fetch(`${postsUrl}/${id}`).then((response) => {
    if (!response.ok) throw new Error(response.statusText);
    return response.json();
  });
}
