import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUser } from "../../../services/api/jsonplaceholder/users";
import User from "../../../models/User";
import UserCard from "../../../components/UserCard";
import { ValidationUtils } from "../../../utils/ValidationUtils";

function PostOwnerPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  const userId = Number(params.userId);

  useEffect(() => {
    if (!ValidationUtils.isIdValid(userId)) {
      navigate("/not-found", { replace: true });
      return;
    }

    fetchUser(userId)
      .then((user) => {
        setUser(user);
      })
      .catch(() => {
        navigate("/not-found", { replace: true });
      });
  }, [navigate, userId]);

  return <div>{user && <UserCard user={user} />}</div>;
}

export default PostOwnerPage;
